.App {
  text-align: center;
  color: aliceblue;
  
}
body{
  color: aliceblue;
}

h1{
  color: aliceblue;
}

.centered{
  text-align: center;

}

.gallery{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.thumb-preview{
  width: 10%;
  margin: 0.3em;
  transition: 0.5s;
}
.thumb-preview:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
@media (max-width: 1500px) {
  .thumb-preview{

  min-width:18vw;
  }
}
@media (max-width: 1000px) {
  .thumb-preview{
  min-width:30vw;
  }
}

.nostylebtn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.notification-badge {
  position: absolute;
  top: -10px; /* Position above the button */
  right: -10px; /* Position to the right of the button */
  background-color: red;
  color: white;
  padding: 5px 5px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  min-width: 20px;
  text-align: center;
  line-height: 1;
  pointer-events: none; /* To avoid interaction with the badge */
}

.filterbutton{
  position: relative; /* For positioning the badge inside the button */

  font-size: 16px;
}
.front-nav{
  display: flex;
  justify-content: center;
}
.page-card{
  border: solid 1px white ;
  border-radius: 30px;
  width: 25%;
  padding-bottom: 25%;
  margin: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 2px 2px 10px grey;
  transition: transform .2s; /* Animation */
  position:relative;
}

.text{
  width: 40%;
  margin:auto;
}

@media (max-width: 1000px) {
  .text{
    width: 100%;
    padding: 1em;
  }
}

.page-card:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.page-card-title{
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  position:absolute;
  right: 0;
  left: 0;
  top: 45%;
  bottom: 0;
  margin: auto;
  text-shadow: 2px 2px 3px #181818,-2px -2px 3px #181818;
  font-size: 4vw;
}

.container {
  display: flex;
  padding-top:2em;

}

.rule{
  font-family: 'Courier New', Courier, monospace;
  position: fixed;
  top:0;
  left:0;
  background-color: #181818;
  width:100%;
  text-align: center;
  text-wrap: wrap;
  overflow-wrap: break-word;
}
.rule-text{
  width: 100%;
  font-size: 2em;

}

.subtitle{
  text-align: left;
}

.table-cell{
  display: table-cell;
}
.table-row{
  display:table-row;

}

.right-align{

  text-align: right;
}
.left-align{

  text-align: left;
}

.stat-header{

}
.table-view{
display: table;
font-size: 1.2em;  width: 100%;
padding:4px
}

img{
max-width: 100%; height: auto;
}

.large-image-view{
  margin-top: 1em;
  margin-bottom: 1em;
  border: 1px solid white;

  width: 80%;
}

.smaller-view{
  border: 1px solid white;
  margin: 1em;

}        

.right-column{
  width: 40%;
}
.shadow-border {
  border: 2px solid lightgray;
  box-shadow: 2px 2px 10px grey;
} 


.controls{
  background-color: #181818;
  padding: 1em;
  bottom: 0;
  position: fixed;
  width: 100%;
}

@media (max-width: 1000px) {
  .container {
      flex-direction: column; /* Stack the divs vertically */
      align-items: center; /* Center the divs */
  } 

  .controls{
    position: fixed;
    bottom: 0;
    width:100%;

  }
  .App{
    margin-bottom: 5em;
  }
  .rule-text{
    font-size: 1em;
  }
  .right-column{
    width: 100%;
    margin: 0;
  }
  .large-image-view{
    width: 100%;
  }
  .smaller-view{
    margin:0;
    margin-bottom: 1em;
  }
}
